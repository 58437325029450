<template>
  <div class="event-list">
    <div
      v-for="(event, index) in eventList"
      :key="index"
      class="event-block"
    >
      <SectionTitle
        class="event-block__title"
        :title="event.Title"
      />
      <div class="event-block-content">
        <v-lazy
          class="event-block__banner"
          :class="{ 'no-grid': event.ExtensionBanners.length === 0 }"
        >
          <a
            class="event-block__banner-link"
            @click.stop="(e) => goLink(e, event.LinkUrl)"
          >
            <img
              :src="event.BannerUrl"
              :alt="event.Title"
            >
          </a>
        </v-lazy>
        <div
          v-if="event.ExtensionBanners.length > 0"
          class="event-block__grid"
        >
          <v-lazy
            v-for="(child, childIndex) in event.ExtensionBanners"
            :key="childIndex"
            class="event-block__item"
          >
            <a
              @click.stop="(e) => goLink(e, child.LinkUrl, child.GAParameter)"
            >
              <img
                :src="child.ImageUrl"
                alt="child section"
              >
            </a>
          </v-lazy>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from '@/components/ComponentHomeSectionTitle'

export default {
  components: {
    SectionTitle
  },
  props: {
    eventList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  methods: {
    goLink (e, url, GAParameter) {
      e.preventDefault()
      if (url) {
        window.open(url, '_blank')
        if (GAParameter) {
          this.$gaLogEvent('首頁', `click_活動專區_${GAParameter}`)
        } else {
          this.$gaLogEvent('首頁', 'click_活動專區')
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
$sectionMarginX: 16px;
$sectionMarginY: 24px;
$sectionTitleMarginBottom: 32px;
$sliderShadowPadding: 20px;
@mixin swiperSectionTitleDefinedApp () {
  justify-content: flex-start;
  max-width: none;
  margin: 0 auto calc(#{$sectionTitleMarginBottom} - #{$sliderShadowPadding});
  padding: 0 $sectionMarginX;
}

.event-block {
  margin-bottom: 33px;

  &:last-child {
    margin-bottom: 0;
  }

  &__title {
    @include swiperSectionTitleDefinedApp;
  }

  &__content {
    box-shadow: 0 4.3px 12.9px 0 rgba(0, 0, 0, 0.1);
  }

  &__banner {
    margin: 0 $sectionMarginX;
    overflow: hidden;
    aspect-ratio: 50/19;
    background-color: #fff;
    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #f3f3f3;

    &-link {
      display: flex;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    &.no-grid {
      border-radius: 10px;
      border-bottom: 0;
    }
  }

  &__grid {
    display: flex;
    justify-content: flex-start;
    margin: 0 $sectionMarginX;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    background-color: #fff;
  }

  &__item {
    width: calc(100% / 3);
    box-sizing: border-box;
    aspect-ratio: 400/329;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 1px;
      height: 100%;
      background-color: #f3f3f3;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    a {
      display: flex;
      width: 100%;
      height: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}
</style>
