<template>
  <div
    class="card-rating"
    :data-service-id="rating.serviceId"
  >
    <div class="card-rating__info-container">
      <span class="card-rating__info-container__name">{{ rating.userName }}</span>
      <span class="card-rating__info-container__divider">|</span>
      <span class="card-rating__info-container__service-title">
        {{ serviceTitle }}
      </span>
      <span class="card-rating__info-container__time">{{ rating.orderTime }}</span>
    </div>
    <div class="card-rating__score-container">
      <span class="card-rating__score__icon">
        <i
          v-for="(score, index) in 5"
          :key="index"
          :class="['icon-star-filled', { 'icon-star-filled--empty': index >= rating.score }]"
          class="icon-star-filled"
        />
      </span>
    </div>
    <div class="card-rating__content">
      <div
        class="card-rating__img-container"
      >
        <img
          v-for="(img, index) in rating.imgList"
          :key="index"
          class="card-rating__img-container__img swiper-lazy"
          :data-src="img"
          :src="lazyImg ? require('@/assets/image/article/default-cover.png') : img"
          loading="lazy"
        >
      </div>
      <div class="card-rating__score-desc">
        <p>
          <template v-for="(desc, index) in rating.scoreDesc">
            {{ desc }}
            <br
              v-if="index < rating.scoreDesc.length - 1"
              :key="index"
            >
          </template>
        </p>
      </div>
    </div>
    <div
      class="card-rating__score-tag-container"
    >
      <v-chip
        v-for="(tag, tagIndex) in rating.tagList"
        :key="tagIndex"
        class="card-rating__score-tag-container__tag"
      >
        {{ tag.name }}
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rating: {
      type: Object,
      default: () => {}
    },
    lazyImg: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    serviceTitle () {
      return this.rating.serviceTitle && this.rating.serviceTitle.length > 8 ? this.rating.serviceTitle.slice(0, 8) + '...' : this.rating.serviceTitle
    }
  }
}
</script>

<style lang="scss" scoped>
.card-rating {
  $cardBorderRadius: 12px;
  border-radius: $cardBorderRadius;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.06);
  padding: 22px 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: $whiteColor;
  &:hover {
    cursor: pointer;
    box-shadow: 0 9px 24px 0 rgba(0, 0, 0, 0.1);
    transform: translateY(-3px);
    transition: ease-in-out 200ms;
  }
  &__img-container {
    margin-bottom: 10px;
    &__img {
      width: 72px;
      height: 72px;
      display: inline-block;
      margin-right: 8px;
      border-radius: 4px;
      background-position: center;
      object-fit: cover;
      background-repeat: no-repeat;
      background-color: $lightGrayColor;
    }
  }
  &__info-container {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-start;
    color: $midGrayColor;
    font-size: pxTorem(15px);
    &__service-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__divider {
      display: inline-block;
      margin: 0 6px;
    }
    &__name {
      color: $fontColor;
      font-weight: bold;
      font-size: pxTorem(15px);
      white-space: nowrap;
      flex-shrink: 0;
    }
    &__time {
      font-size: pxTorem(14px);
      color: $midGrayColor;
      flex-shrink: 0;
      white-space: nowrap;
      margin-left: auto;
    }
  }
  &__score-desc {
    p {
      @include lineClamp(3);
      font-size: pxTorem(15px);
    }
    margin-bottom: 12px;
  }
  &__score__icon {
    color: $yellowColor;
    .icon-star-filled {
      font-size: pxTorem(18px);
      padding-right: 2px;
      &--empty {
        color: #ccc;
      }
    }
  }
  &__score-container {
    margin-bottom: 16px;
  }
  &__score-tag-container {
    margin-top: auto;
    &__tag {
      &.theme--light {
        background-color: $lightGrayColor;
        color: $fontColor;
        font-weight: bold;
        font-size: pxTorem(13px);
      }
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
}
@media screen and (max-width: $mobileWidth) {
  .card-rating {
    padding: 16px;
    &__img-container {
      order: 2;
      margin-bottom: 0;
      &__img {
        width: 55px;
        height: 55px;
        margin: 0;
        margin-left: 16px;
        display: none;
        &:nth-of-type(1) {
          display: block;
        }
      }
    }
    &__score-container {
      margin-bottom: 12px;
    }
    &__score-tag-container {
      display: none;
      &__tag {
        margin-bottom: 12px;
      }
    }
    &__content {
      display: flex;
    }
    &__score-desc {
      font-size: pxTorem(14px);
      margin-bottom: 0;
      p {
        margin-bottom: 0;
        font-size: pxTorem(14px);
      }
    }
    &__info-container {
      font-size: pxTorem(13px);
      &__time {
        font-size: pxTorem(12px);
      }
    }
  }
}
</style>
