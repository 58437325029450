<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div
    id="page-homepage"
    class="page-homepage"
    :class="{ 'is-app' : $store.state.isApp }"
  >
    <div
      v-if="banner.list.length"
      class="homepage-banner-swiper-container"
      @click="clickBannerHandler"
    >
      <div
        ref="bannerSwiper"
        v-swiper:bannerSwiper="banner.sliderOptions"
        class="homepage-banner-swiper"
        @slideChange="bannerSlideChangeHandler('bannerSwiper', 'banner')"
      >
        <div class="swiper-wrapper">
          <div
            v-for="banner in banner.list"
            :key="banner.Id"
            class="swiper-slide homepage-banner-swiper__slide"
            :data-link_uri="banner.link_uri"
            :data-title="banner.title"
          >
            <img
              class="homepage-banner-swiper__image"
              :src="banner.ImageUri"
              :alt="banner.title"
            >
          </div>
        </div>
        <div
          v-if="showBannerSwiperArrow"
          slot="pagination"
          class="swiper-pagination swiper-pagination-custom"
        >
          <span
            v-for="(dot, index) in banner.list"
            :key="dot.Id"
            class="swiper-pagination-btn"
            :class="{ 'active': index === banner.activeIndex, 'one-child': banner.list.length === 1 }"
            @click="clickBannerPaginationHandler(index)"
          >
            <span class="swiper-pagination-btn__text">{{ banner.list[index].title }}</span>
          </span>
        </div>
        <div
          v-show="showBannerSwiperArrow && !$store.state.isApp"
          :slot="showBannerSwiperArrow ? 'button-prev' : ''"
          class="swiper-arrow-controls inside"
        >
          <v-btn
            class="prev btn banner-swiper-prev-btn"
            fab
          >
            <i class="icon icon-arrow-left-bold" />
          </v-btn>
        </div>
        <div
          v-show="showBannerSwiperArrow && !$store.state.isApp"
          :slot="showBannerSwiperArrow ? 'button-next' : ''"
          class="swiper-arrow-controls inside"
        >
          <v-btn
            class="next btn banner-swiper-next-btn"
            fab
          >
            <i class="icon icon-arrow-right-bold" />
          </v-btn>
        </div>
      </div>
    </div>
    <v-skeleton-loader
      v-show="!canShowBannerSwiper"
      class="homepage-loader__banner"
      type="image"
    />
    <section
      v-if="!$store.state.isApp && recommendService.list && recommendService.list.length"
      class="recommend-service-section"
    >
      <SectionTitle
        class="recommend-service-section__title"
        title="推薦服務"
      />
      <GroupServiceList
        class="recommend-service-section__service-list"
        :service-list="recommendService.list"
        service-parent-type-id="star"
      />
      <div
        v-if="recommendService.showSeeMoreBtn && recommendService.list"
        class="recommend-service-section__see-more"
      >
        <v-btn
          class="recommend-service-section__see-more__btn"
          outlined
          color="primary"
          @click="goServiceHandler"
        >
          查看更多
          <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </div>
    </section>
    <section
      v-if="!$store.state.isApp && (!recommendService.list || !recommendService.list.length)"
      class="homepage-loader__card-container"
    >
      <v-skeleton-loader
        v-for="x in 8"
        :key="x"
        class="homepage-loader__card-container__card"
        type="card, list-item-three-line"
      />
    </section>
    <section
      v-if="$store.state.isApp && communityService.list.length"
      class="community-service-section"
    >
      <SectionTitle
        class="community-service-section__title"
        title="社區專屬服務"
      />
      <GroupCommunityServiceList
        class="community-service-section__service-list"
        :service-list="communityService.list"
      />
    </section>
    <section
      v-if="$store.state.isApp && featuredSection.list.length"
      class="featured-section"
    >
      <SectionTitle
        class="featured-section__title"
        title="精選專區"
      >
        <v-btn
          class="featured-section__see-all__btn"
          text
          color="#999"
          @click="goServiceHandler"
        >
          查看全部
          <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </SectionTitle>
      <GroupFeaturedSectionList
        :section-list="featuredSection.list"
      />
    </section>
    <section
      v-if="customerRating.list.length"
      class="customer-rating-section"
    >
      <SectionTitle
        class="customer-rating-section__title"
        title="用戶好評"
      />
      <div
        class="customer-rating-card-container card-swiper-container"
        @click="clickCustomerRatingHandler"
      >
        <v-lazy
          :options="{ rootMargin: '0px' }"
          transition="fade-transition"
        >
          <div
            id="customer-rating-swiper"
            ref="customerRatingSwiper"
            v-swiper:customerRatingSwiper="customerRating.sliderOptions"
            class="swiper"
            @slideChange="bannerSlideChangeHandler('customerRatingSwiper', 'customerRating')"
          >
            <div class="swiper-wrapper">
              <div
                v-for="rating in customerRating.list"
                :key="rating.id"
                class="swiper-slide customer-rating-swiper-slide"
              >
                <CardRating
                  class="customer-rating-card"
                  :rating="rating"
                  lazy-img
                />
              </div>
            </div>
            <div
              v-show="!$store.state.isApp && !isMobile"
              :slot="showCustomerSwiperArrow ? 'button-prev' : ''"
              class="swiper-arrow-controls rating"
            >
              <v-btn
                class="prev btn rating-swiper-prev-btn"
                fab
              >
                <i class="icon icon-arrow-left-bold" />
              </v-btn>
            </div>
            <div
              v-show="!$store.state.isApp && !isMobile"
              :slot="showCustomerSwiperArrow ? 'button-next' : ''"
              class="swiper-arrow-controls rating"
            >
              <v-btn
                class="next btn rating-swiper-next-btn"
                fab
              >
                <i class="icon icon-arrow-right-bold" />
              </v-btn>
            </div>
          </div>
        </v-lazy>
      </div>
    </section>
    <section
      v-if="$store.state.isApp && event.list.length"
      class="event-section"
    >
      <GroupEventList :event-list="event.list" />
    </section>
    <section
      v-if="!$store.state.isApp"
      class="smartdaily-intro-section"
    >
      <div class="smartdaily-intro__container">
        <div
          v-for="(info, index) in smartdailyInfo.list"
          :key="index"
          class="smartdaily-intro"
        >
          <v-lazy>
            <div
              class="smartdaily-intro__img"
              data-aos="zoom-in"
            >
              <img
                loading="lazy"
                :alt="info.title"
                :src="require(`~/assets/image/homepage/${info.img}.png`)"
              >
            </div>
          </v-lazy>
          <div class="smartdaily-intro__title">
            <p>{{ info.title }}</p>
          </div>
          <div class="smartdaily-intro__desc">
            <p
              v-for="(desc, descIndex) in info.descList"
              :key="descIndex"
            >
              {{ desc }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      v-if="video.list.length"
      class="video-section"
    >
      <SectionTitle
        class="video-section__title"
        title="生活實用小知識"
      />
      <GroupVideoList :video-list="video.list" />
    </section>
    <section
      v-if="recommendedArticle.list.length"
      class="recommend-article-section"
    >
      <SectionTitle
        class="recommend-article-section__title"
        title="推薦文章"
      />
      <div class="recommend-article-section__articles-container">
        <ArticleCard
          v-for="article in recommendedArticle.list"
          :key="`${'recommend-article' + article.Id}`"
          class="recommend-article-section__article"
          :content="article"
          :display="isMobile ? 'column' : 'row'"
          :img-width="isMobile ? '' : '250px'"
          :img-height="isMobile ? '' : '120px'"
          :aspect-ratio="isMobile ? recommendedArticle.imageAspectRatio : 0"
          :show-content="false"
          :show-title-on-img="false"
          @click.native="goArticlePage(article)"
        />
      </div>
      <div
        v-if="recommendedArticle.showSeeMoreBtn"
        class="recommend-article-section__see-more"
      >
        <v-btn
          class="recommend-article-section__see-more__btn"
          outlined
          color="primary"
          @click="goInformationStationHandler"
        >
          查看更多
          <i class="icon icon-arrow-right-bold" />
        </v-btn>
      </div>
    </section>
    <section
      v-if="!$store.state.isApp && recommendShare.list.length"
      class="recommend-share-section"
    >
      <SectionTitle
        class="recommend-share-section__title"
        title="真實推薦分享"
      />
      <div class="recommend-share-card-container card-swiper-container">
        <div
          id="recommend-share-swiper"
          ref="recommendShareSwiper"
          v-swiper:recommendShareSwiper="recommendShare.sliderOptions"
          class="swiper"
        >
          <div class="swiper-wrapper">
            <div
              v-for="(sharePost, index) in recommendShare.list"
              :key="index"
              class="swiper-slide recommend-share-swiper-silde"
            >
              <v-lazy>
                <CardBlogShare
                  class="recommend-share-card"
                  :share-post="sharePost"
                  @click.native="openShareArticleHandler(sharePost)"
                />
              </v-lazy>
            </div>
            <div class="swiper-slide fake-slide" />
          </div>
          <div
            v-show="!isMobile"
            slot="button-prev"
            class="swiper-arrow-controls recommend-share"
          >
            <v-btn
              class="prev btn recommend-share-swiper-prev-btn"
              fab
            >
              <i class="icon icon-arrow-left-bold" />
            </v-btn>
          </div>
          <div
            v-show="!isMobile"
            slot="button-next"
            class="swiper-arrow-controls recommend-share"
          >
            <v-btn
              class="next btn recommend-share-swiper-next-btn"
              fab
            >
              <i class="icon icon-arrow-right-bold" />
            </v-btn>
          </div>
        </div>
      </div>
    </section>
    <div
      class="server-log"
      style="display:none;"
      v-text="serverLog"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionTitle from '@/components/ComponentHomeSectionTitle'
import GroupServiceList from '@/components/GroupServiceList'
import GroupCommunityServiceList from '@/components/GroupCommunityServiceList'
import GroupEventList from '@/components/GroupEventList'
import GroupFeaturedSectionList from '@/components/GroupFeaturedSectionList.vue'
import GroupVideoList from '../components/GroupVideoList.vue'
import CardRating from '@/components/Card/CardRating'
import CardBlogShare from '@/components/Card/CardBlogShare'
import ArticleCard from '@/components/PageComponent/ArticleSection/ArticleCard'
import recommendShareBlog from '@/static/recommendShareBlog.json'
import getYoutubeID from '@/mixin/getYoutubeID'
export default {
  components: {
    SectionTitle,
    GroupServiceList,
    CardRating,
    CardBlogShare,
    ArticleCard,
    GroupCommunityServiceList,
    GroupEventList,
    GroupFeaturedSectionList,
    GroupVideoList
  },
  mixins: [getYoutubeID],
  beforeRouteLeave (to, from, next) {
    try {
      const removeHoverListener = (refKey) => {
        const ref = this.$refs?.[refKey]?.$el
        if (ref) {
          ref.removeEventListener('mouseover', this[`${refKey}MouseoverListener`])
          ref.removeEventListener('mouseleave', this[`${refKey}MouseleaveListener`])
          ref.swiper.destroy = () => {} // 防止destroys時slider的spacebetween被重置所導致的卡片位移
        }
      }
      removeHoverListener('bannerSwiper')
      removeHoverListener('customerRatingSwiper')
      if (this.$refs.recommendShareSwiper) {
        this.$refs.recommendShareSwiper.swiper.destroy = () => {}
      }
      next()
    } catch (err) {
      console.log('err', err)
    }
  },
  async asyncData ({ params, route, env, store, redirect }) {
    return {
      id: params.id,
      description: store.state.meta.description,
      routeFullPath: route.fullPath,
      webBaseUrl: String(env.BASE_WEB_URL || ' ').slice(0, -1)
    }
  },
  data () {
    return {
      title: '智樂家-家電清洗、居家清潔、專業到府服務，一次解決家的大小事',
      image: null,
      isLoading: true,
      // 首頁banner
      banner: {
        list: [],
        sliderOptions: {
          loop: true,
          watchOverflow: true,
          touchEventsTarget: 'wrapper',
          preloadImages: true,
          autoplay: {
            delay: 8000,
            disableOnInteraction: false
          },
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
            type: 'custom'
          },
          navigation: {
            nextEl: '.banner-swiper-next-btn',
            prevEl: '.banner-swiper-prev-btn'
          }
        },
        activeIndex: 0,
        loadDone: false
      },
      // 推薦服務
      recommendService: {
        list: null,
        showSeeMoreBtn: false
      },
      // 社區專屬服務
      communityService: {
        list: []
      },
      // 精選專區
      featuredSection: {
        list: []
      },
      // 用戶好評
      customerRating: {
        list: [],
        sliderOptions: {
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          loop: true,
          loopedSlides: 20,
          slidesPerColumnFill: 'row',
          spaceBetween: 16,
          slidesPerView: 'auto',
          preloadImages: false,
          lazy: true,
          watchSlidesVisibility: true,
          breakpoints: {
            768: {
              spaceBetween: 8
            }
          },
          navigation: {
            nextEl: '.rating-swiper-next-btn',
            prevEl: '.rating-swiper-prev-btn'
          }
        }
      },
      // 固定資訊區塊
      smartdailyInfo: {
        list: [
          {
            img: 'service-quality',
            title: '專業服務品質',
            descList: [
              '智生活嚴選',
              '專業廠商與技師'
            ]
          },
          {
            img: 'standard-process',
            title: '安心標準流程',
            descList: [
              '真實評價',
              '透明價格',
              '保固承諾'
            ]
          },
          {
            img: 'easy-order',
            title: '便利預約訂購',
            descList: [
              '快速下單',
              '即時預約',
              '多元金流'
            ]
          },
          {
            img: 'user-experience',
            title: '尊榮會員體驗',
            descList: [
              '300+人服務團隊',
              '專屬會員優惠'
            ]
          }
        ]
      },
      // 推薦文章
      recommendedArticle: {
        list: [],
        showSeeMoreBtn: true,
        imageAspectRatio: 114 / 70,
        // 文章取值主分類id
        aritcleMainTypeIds: [39, 69]
      },
      // 真實推薦分享
      recommendShare: {
        list: [],
        sliderOptions: {
          slidesPerColumnFill: 'row',
          spaceBetween: 24,
          loop: false,
          slidesPerView: 'auto',
          breakpoints: {
            768: {
              spaceBetween: 16
            }
          },
          navigation: {
            nextEl: '.recommend-share-swiper-next-btn',
            prevEl: '.recommend-share-swiper-prev-btn'
          }
        }
      },
      // 生活實用小知識
      video: {
        list: []
      },
      // 活動專區
      event: {
        list: []
      },
      userDefaultCommunity: {},
      bannerSwiperMouseoverListener: null,
      bannerSwiperMouseleaveListener: null,
      customerRatingSwiperMouseoverListener: null,
      customerRatingSwiperMouseleaveListener: null,
      serverLog: ''
    }
  },
  async fetch () {
    try {
      await this.$store.dispatch('getUserDefaultCommunity')
      await this.setBannerList()
      let promises = []
      if (this.$store.state.isApp) {
        promises = [
          this.setCustomerRating(),
          this.setRecommendedArticleList(),
          this.setFeaturedSectionList(),
          this.setVideoList(),
          this.setEventList(),
          this.setCommunityList()
        ]
      } else {
        promises = [
          this.setRecommendShareBlogList(),
          this.setCustomerRating(),
          this.setRecommendServiceList(),
          this.setRecommendedArticleList(),
          this.setVideoList()
        ]
      }
      await Promise.all(promises)
      if (this.$auth.loggedIn) {
        await this.$store.dispatch('checkIsGuestMember', { Phone: this.$auth.user.APhone })
      }
    } catch (err) {
      console.log('err', err)
    }
  },
  head () {
    return {
      title: this.title,
      titleTemplate: null,
      meta: [
        { hid: 'title', property: 'title', content: this.title },
        { hid: 'og:url', property: 'og:url', content: this.webBaseUrl + this.routeFullPath },
        { hid: 'og:title', property: 'og:title', content: this.title },
        { hid: 'twitter:url', property: 'twitter:url', content: this.webBaseUrl + this.routeFullPath },
        { hid: 'twitter:title', property: 'twitter:title', content: this.title }
      ],
      link: this.bannerListPreload
    }
  },
  computed: {
    ...mapGetters(['isMobile']),
    canShowBannerSwiper () {
      return this.banner.loadDone || this.banner.list.length === 0
    },
    fetchAllDone () {
      return !this.isLoading && !this.$fetchState.pending
    },
    showBannerSwiperArrow () {
      return this.banner.list.length > 1
    },
    showCustomerSwiperArrow () {
      return this.customerRating.list.length > 3
    },
    processClient () {
      return process.client
    },
    bannerListPreload () {
      if (this.banner.list.length) {
        return this.banner.list.map(banner => {
          return {
            rel: 'preload',
            as: 'image',
            href: banner.ImageUri
          }
        })
      }
      return []
    }
  },
  watch: {
    fetchAllDone (val) {
      if (val) {
        this.$nextTick(() => {
          const addHoverListener = (refKey) => {
            const ref = this.$refs[refKey]?.$el
            if (!ref) return
            this[`${refKey}MouseoverListener`] = () => {
              this.$refs[refKey]?.swiper?.autoplay?.stop()
            }
            this[`${refKey}MouseleaveListener`] = () => {
              this.$refs[refKey]?.swiper?.autoplay?.start()
            }
            ref.addEventListener('mouseover', this[`${refKey}MouseoverListener`])
            ref.addEventListener('mouseleave', this[`${refKey}MouseleaveListener`])
          }
          if (!this.isMobile) addHoverListener('bannerSwiper')
          if (this.showCustomerSwiperArrow) addHoverListener('customerRatingSwiper')
        })
      }
    },
    isMobile () { // resize後需重新init
      this.$nextTick(() => {
        this.resetSwiper('bannerSwiper')
      })
    }
  },
  async mounted () {
    try {
      this.$store.commit('setWindowWidth', window.innerWidth)
      this.isLoading = false
    } catch (err) {
      console.log('err', err)
    }
  },
  methods: {
    // api
    async setCustomerRating () {
      try {
        const { data } = await this.$axios.get(`/api/v2/Main/PositiveRating?IsWeb=${this.$store.state.isApp}`, { useCache: true })
        this.customerRating.list = data.Data.Ratings.map(this.handleRatingDataFromAPI).slice(0, 20)
        // 如果卡片數量等於4個右滑會無法無限滾動，複製卡片以解決此問題
        if (this.customerRating.list.length === 4) {
          this.customerRating.list = [
            ...this.customerRating.list,
            ...this.customerRating.list.map(item => ({ ...item, id: item.id + 4 }))
          ]
        }
        this.customerRating.sliderOptions.loop = this.showCustomerSwiperArrow
        if (!this.showCustomerSwiperArrow) {
          this.customerRating.sliderOptions.autoplay = false
        }
      } catch (err) {
        console.log('err', err)
        return Promise.reject(err)
      }
    },
    async setBannerList () {
      try {
        const postData = { Tag: 'homepage' }
        const bannerList = await this.$store.dispatch('getBannerList', postData)
        this.banner.list = bannerList
          .map(banner => {
            banner.ImageUri = banner.img_uri
            return banner
          })
        this.banner.list = this.banner.list.splice(0, 6)
        this.banner.sliderOptions.loop = this.showBannerSwiperArrow
        if (!this.showBannerSwiperArrow) {
          this.banner.sliderOptions.autoplay = false
        }
        this.banner.loadDone = true
      } catch (err) {
        console.log(err)
        return Promise.resolve()
      }
    },
    async setRecommendServiceList () {
      try {
        const recommendServiceList = await this.$store.dispatch('service/getStarServiceList')
        this.serverLog += `setRecommendServiceList: ${JSON.stringify(recommendServiceList)} \n`
        this.recommendService.showSeeMoreBtn = recommendServiceList.length > 8
        this.recommendService.list = recommendServiceList.slice(0, 8)
      } catch (err) {
        console.log('err', err)
        this.serverLog += `setRecommendServiceList err: ${err} \n`
        this.recommendService.list = []
        return Promise.reject(err)
      }
    },
    async setRecommendedArticleList () {
      try {
        const postData = {
          MainTypeIds: this.recommendedArticle.aritcleMainTypeIds,
          'PagingSetting.Offset': 0,
          'PagingSetting.Count': 4
        }
        const response = await this.$axios.get('api/v1/Article/Banner', { params: postData, useCache: true })
        this.recommendedArticle.showSeeMoreBtn = response.data.Data.Articles.length >= 4
        this.recommendedArticle.list = this.formatArticleData(response.data.Data.Articles).slice(0, 4)
      } catch (err) {
        console.log('err', err)
        return Promise.reject(err)
      }
    },
    async setFeaturedSectionList () {
      try {
        const postData = {
          IsWeb: !this.$store.state.isApp
        }
        const params = await this.setCommunityParams(postData)
        const response = await this.$axios.get('api/Essential/HomePage', { params })
        if (!response.data.Data || !response.data.Data.Essentials) return
        this.featuredSection.list = response.data.Data.Essentials
        // 最多顯示16個
        this.featuredSection.list = this.featuredSection.list.slice(0, 16)
      } catch (err) {
        console.log('err', err)
      }
    },
    async setVideoList () {
      try {
        const { data } = await this.$axios.get('api/Video/HomePage')
        if (!data.Data || !data.Data.Videos) return
        this.video.list = data.Data.Videos.map((video, index) => {
          const videoId = this.getYoutubeID(video.VideoUrl)
          return {
            ...video,
            cover: this.getYoutubeCover(videoId),
            videoId
          }
        })
      } catch (err) {
        console.log('err', err)
      }
    },
    async setCommunityList () {
      try {
        const postData = {
          IsWeb: !this.$store.state.isApp
        }
        const params = await this.setCommunityParams(postData)
        const { data } = await this.$axios.get('api/StarService/HomePage', { params })
        if (!data.Data || !data.Data.RecommendServices) return
        this.communityService.list = data.Data.RecommendServices
      } catch (err) {
        console.log('err', err)
      }
    },
    async setEventList () {
      try {
        const postData = {}
        const params = await this.setCommunityParams(postData)
        const { data } = await this.$axios.get('api/Campaign/HomePage', { params })
        if (!data.Data || !data.Data.Campaigns) return
        this.event.list = data.Data.Campaigns
      } catch (err) {
        console.log('err', err)
      }
    },
    setRecommendShareBlogList () {
      this.recommendShare.list = _.shuffle(recommendShareBlog.recommendShareBlog)
    },
    /**
     * 為請求參數設置社區相關資訊
     * @param {Object} postData - 需要添加社區參數的原始物件
     * @returns {Object} 添加社區參數後的物件
     */
    async setCommunityParams (postData = {}) {
      const userDefaultCommunity = await this.$store.dispatch('getUserDefaultCommunity')

      if (userDefaultCommunity) {
        postData.RegionId = userDefaultCommunity?.AreaId || (userDefaultCommunity?.Id ? 0 : 28) // 社區和地區id都不存在時，預設台北市萬華區
        postData.ComId = userDefaultCommunity?.Id || 0
      } else {
        postData.RegionId = 28 // 預設台北市萬華區
        postData.ComId = 0
      }

      return postData
    },
    formatArticleData (articleData) {
      return articleData.map(data => {
        data.CreateTime = this.$dayjs(data.CreateAt).format('YYYY/MM/DD')
        if (!data.CoverPictureUrl) {
          data.CoverPictureUrl = require('@/assets/image/article/default-cover.png')
        }
        return data
      })
    },
    handleRatingDataFromAPI (d, index) {
      d.tagList = d.Tags.slice(0, 3)
      // 把 userName 的 * 換成 ＊
      const userName = d.Username ? d.Username.replace(/\*/g, '＊') : ''
      return {
        id: d.ScoreId,
        userName,
        serviceId: d.ServiceId,
        serviceTitle: d.ServiceTitle,
        orderTime: this.$dayjs(new Date(d.PostTime)).format('YYYY/MM/DD'),
        score: d.Rating,
        scoreDesc: d.Comment.split('\n'),
        imgList: d.ImageUrls.slice(0, 3),
        tagList: d.tagList
          .map(tag => {
            return {
              id: index,
              name: tag
            }
          })
      }
    },
    clickBannerHandler (e) {
      if (e.target.classList.contains('homepage-banner-swiper__image')) {
        const banner = e.target.parentElement
        const linkUri = banner.getAttribute('data-link_uri')
        const title = banner.getAttribute('data-title')
        if (linkUri) window.open(linkUri, '_blank')
        this.$gaLogEvent('首頁', `click_banner_${title}`)
      }
    },
    clickCustomerRatingHandler (e) {
      const cardContainer = e.target.closest('.card-rating')
      if (cardContainer) {
        const serviceId = cardContainer.getAttribute('data-service-id')
        this.goServiceComment(serviceId)
      }
    },
    // slider action
    clickBannerPaginationHandler (index) {
      this.$refs.bannerSwiper.swiper.slideToLoop(index, 200, false)
      this.banner.activeIndex = index
    },
    bannerSlideChangeHandler (refKey, datakey) {
      const { isBeginning, isEnd, realIndex } = this.$refs[refKey].swiper
      this[datakey].activeIndex = realIndex
      if (isBeginning || isEnd) {
        this.resetSwiper(refKey)
      }
    },
    resetSwiper (refKey) {
      this.$refs[refKey]?.swiper?.loopDestroy()
      this.$refs[refKey]?.swiper?.loopCreate()
      this.$refs[refKey]?.swiper?.update()
    },
    // route action
    goArticlePage (article) {
      const articleRoute = {
        name: 'informationStation-article-idsn',
        params: { idsn: article.Id }
      }
      const fullLink = `${window.location.origin}${this.$nuxt.$router.resolve(articleRoute).resolved.fullPath}`
      this.$gaLogEvent('首頁', `click_文章_${fullLink}`)
      this.$nuxt.$router.push(articleRoute)
    },
    async goServiceHandler () {
      if (this.$store.state.isApp) {
        // 返回會回到此頁
        window.location.href = 'https://inapp.kingnetsmart.com.tw/ad.html?page=LS_A_Clist&title=生活服務&referrer='
        this.$gaLogEvent('首頁', 'click_查看更多_精選服務')
        return
      }
      this.$gaLogEvent('首頁', 'click_查看更多_熱門服務')
      this.$nuxt.$router.push({ name: 'index-id', params: { id: 'star', toTop: true } })
    },
    goServiceComment (serviceId) {
      this.$gaLogEvent('首頁', 'click_評價')
      if (this.$store.state.isApp) {
        window.location.href = `https://inapp.kingnetsmart.com.tw/ad.html?page=LS_A_Intro&params1=${serviceId}&referrer=`
        return
      }
      this.$nuxt.$router.push({ name: 'category-category-service-comment', params: { service: serviceId, category: 'star' } })
    },
    goInformationStationHandler () {
      this.$gaLogEvent('首頁', 'click_查看更多_推薦文章')
      this.$nuxt.$router.push({ name: 'informationStation', params: { toTop: true } })
    },
    openShareArticleHandler (sharePost) {
      this.$gaLogEvent('首頁', 'click_真實推薦分享')
      window.open(sharePost.url, '_blank')
    },
    getYoutubeCover (videoId) {
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    }
  }
}
</script>

<style lang="scss" scoped>
$sectionMarginY: 60px;
$sectionMarginYMobile: 32px;
$sectionTitleMarginBottom: 32px;
$sliderShadowPadding: 20px;
$articleCardMarginBottom: 12px;
$sectionMarginX: 5vw;
$sectionMarginXMobile: 16px;
$contentContainerMaxWidth: calc(263px * 4 + 24px * 3); // 推薦服務區塊寬度限制
$contentContainerMaxWidthMargin: calc(263px * 4 + 24px * 3 + 100px);
$contentContainerMaxWidthMobile: calc(263px * 2 + 24px * 1);
$bannerHeightDesktop: calc(100vw * 380 / 1440);
$bannerHeightMobile: calc(100vw * 380 / 1048);
@mixin sectionLayoutDefined () {
  max-width: calc(#{$contentContainerMaxWidth} + #{$sectionMarginX} * 2);
  margin: $sectionMarginY auto 0;
  margin-bottom: $sectionTitleMarginBottom;
  padding: 0 $sectionMarginX;
  &__title {
    margin-bottom: $sectionTitleMarginBottom;
  }
  @media screen and (max-width: $mobileWidth) {
    max-width: calc(#{$contentContainerMaxWidthMobile} + #{$sectionMarginXMobile} * 2);
    padding: 0 $sectionMarginXMobile;
  }
}
@mixin swiperSectionTitleDefined () {
  max-width: calc(#{$contentContainerMaxWidth} + #{$sectionMarginX} * 2);
  margin: 0 auto calc(#{$sectionTitleMarginBottom} - #{$sliderShadowPadding});
  padding: 0 $sectionMarginX;
  @media screen and (max-width: $mobileWidth) {
    max-width: calc(#{$contentContainerMaxWidthMobile} + #{$sectionMarginXMobile} * 2);
    padding: 0 $sectionMarginXMobile;
  }
}
@mixin seeMoreBtn () {
  display: flex;
  justify-content: center;
  &__btn {
    background-color: $lightGrayColor;
    width: 360px;
    min-height: 48px;
    border: 0;
    ::v-deep {
      .v-btn__content {
        font-weight: bold;
        line-height: normal;
        letter-spacing: normal;
        font-size: pxTorem(15px);
      }
    }
    i {
      font-size: 23px;
      margin-left: 3px;
    }
    i::before {
      color: $mainColor;
    }
  }
  @media screen and (max-width: $mobileWidth) {
    margin: auto;
    &__btn {
      width: 269px;
    }
  }
}
::v-deep {
  $controlsMargin: 40px;
  .slider-container {
    display: block;
    width: 100%;
  }
  .swiper-pagination-custom, .swiper-pagination {
    display: flex;
    justify-content: center;
    bottom: 15px;
    width: 100%;
    left: 0;
    right: 0;
    position: absolute;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      &-active {
        background: $mainColor;
      }
    }
  }
  .swiper-pagination-btn {
    padding: 7px 15px;
    background-color: #fafafa;
    color: $fontColor;
    cursor: pointer;
    $btnRadius: 20px;
    transition: all 0.3s;
    &:first-child {
      border-radius: $btnRadius 0 0 $btnRadius;
    }
    &:last-child {
      border-radius: 0 $btnRadius $btnRadius 0;
    }
    &.one-child {
      border-radius: $btnRadius;
    }
    +.swiper-pagination-btn {
      margin-left: 1px;
    }
    &.active {
      background-color: $mainColor;
      color: $whiteColor;
    }
  }
  .homepage-loader {
    &__banner {
      height: $bannerHeightDesktop;
      .v-skeleton-loader__image {
        height: 100%;
      }
    }
    &__card-container {
      @include sectionLayoutDefined;
      display: flex;
      flex-wrap: wrap;
      &__card {
        $cardMargin: 24px;
        width: calc((100% - #{$cardMargin} * 3) / 4);
        margin-bottom: $cardMargin;
        height: initial;
        &:not(:nth-child(4n)) {
          margin-right: $cardMargin;
        }
      }
    }
  }
}
.page-homepage {
  width: 100%;
  padding-bottom: 60px;
}
.homepage-banner-swiper {
  width: 100%;
  height: $bannerHeightDesktop;
  &-container {
    position: relative;
    overflow: hidden;
  }
  &__image {
    height: $bannerHeightDesktop;
    object-fit: cover;
    width: 100vw;
    &:hover {
      cursor: pointer;
    }
  }
}
::v-deep .swiper-arrow-controls {
  .btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: 0px 4px 8px 1px rgb(75 88 105 / 12%);
    z-index: 11;
    cursor: pointer;
    background: #fff;
    &.v-btn:hover:before { // 移除focus樣式
      opacity: 0 !important;
    }
    @media (hover: hover) {
      &:hover {
        transform: translateY(-50%) scale(1.3);
        transition: all 100ms ease-in-out;
      }
    }
    &.prev {
      left: -60px;
    }
    &.next {
      right: -60px;
    }
    i {
      font-size: pxTorem(20px);
      &::before {
        color: #484848;
      }
    }
  }
  &.inside {
    .prev {
      left: 60px;
    }
    .next {
      right: 60px;
    }
  }
  &.rating, &.recommend-share, &.video-list {
    .prev {
      left: calc(((100vw - #{$contentContainerMaxWidth}) / 2) - 70px);
    }
    .next {
      right: calc(((100vw - #{$contentContainerMaxWidth}) / 2) - 70px);
    }
  }
}
.card-swiper-container { // 卡片swiper
  width: 100%;
  margin-bottom: $sectionMarginY;
  min-height: 300px;
  .v-lazy {
    height: 100%;
  }
  @mixin pseudo-mask-background($direction) {
    content: '';
    width: calc(((100vw - #{$contentContainerMaxWidth}) / 2) + 20px);
    background-image: linear-gradient($direction, transparent, #fff);
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
  }
  .swiper-container {
    position: relative;
    &::after {
      @include pseudo-mask-background(to right);
      right: 0;
    }
    &::before {
      @include pseudo-mask-background(to left);
      left: 0;
    }
  }
  ::v-deep {
    .swiper-slider {
      align-items: stretch;
    }
    .swiper-slide {
      transform: translate3d(calc((100vw - #{$contentContainerMaxWidth}) / 2), 0, 0);
      margin: $sliderShadowPadding 0;
      height: initial;
    }
  }
}
.recommend-service-section { // 推薦服務
  @include sectionLayoutDefined;
  &__see-more {
    @include seeMoreBtn;
  }
}
.customer-rating-section { // 用戶好評
  max-width: 1800px;
  margin: 0 auto;
  margin-top: $sectionMarginY;
  overflow: visible;
  &__title {
    @include swiperSectionTitleDefined;
  }
  .customer-rating-card-container {
    ::v-deep {
      .swiper-slide {
        z-index: 1000;
        width: 365px;
      }
    }
  }
  .card-swiper-container {
    ::v-deep {
      .swiper-slide {
        transform: translate3d(calc((1800px - #{$contentContainerMaxWidth}) / 2), 0, 0);
      }
    }
  }
}
.smartdaily-intro-section { // 官網介紹
  width: 100%;
  background: #f9f9f9;
  .smartdaily-intro {
    width: calc(100% / 4);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $fontColor;
    text-align: center;
    padding: 42px 0;
    &__container {
      @include sectionLayoutDefined;
      margin-top: 0;
      display: flex;
      flex-wrap: wrap;
    }
    &__img {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__title {
      font-size: pxTorem(21px);
      font-weight: bold;
      margin-bottom: 12px;
      p {
        margin-bottom: 0;
      }
    }
    &__desc {
      p {
        margin-bottom: 4px;
      }
    }
  }
}
.recommend-article-section { // 推薦文章
  @include sectionLayoutDefined;
  &__title {
    margin-bottom: 12px;
  }
  &__articles-container {
    display: flex;
    flex-wrap: wrap;
  }
  &__article {
    $articleCardMarginRight: 30px;
    width: calc((100% - #{$articleCardMarginRight}) / 2);
    padding-bottom: $articleCardMarginBottom;
    padding-top: $articleCardMarginBottom;
    &:not(:nth-child(2n)) {
      margin-right: $articleCardMarginRight;
    }
    &:not(:nth-last-child(-n + 2)) {
      border-bottom: 1px solid #eaeaea;
    }
  }
  &__see-more {
    @include seeMoreBtn;
    margin-top: 28px;
  }
  ::v-deep {
    .article-card {
      &__container {
        &:nth-of-type(n) {
          border-bottom: 0;
        }
      }
      &__card-container {
        flex-wrap: nowrap;
        padding: 16px;
      }
      &__img-container {
        flex-shrink: 1;
      }
      &__content-container {
        min-width: 170px;
        flex-shrink: 0;
      }
    }
  }
}
.recommend-share-section { // 部落客分享
  margin-top: $sectionMarginY;
  &__title {
    @include swiperSectionTitleDefined;
  }
  .recommend-share-card-container {
    ::v-deep {
      .swiper-slide {
        width: 348px;
        &.fake-slide {
          width: calc(100vw - #{$contentContainerMaxWidth});
        }
        .basic-verticle-card__img-container {
          display: flex;
        }
        img {
          border: 0;
        }
      }
    }
  }
}
.video-section { // 生活實用小知識
  margin-top: $sectionMarginY;
  position: relative;
  &__title {
    @include swiperSectionTitleDefined;
    margin-bottom: 16px;
  }
}
@media screen and (max-width: 1800px) {
  .customer-rating-section .card-swiper-container {
    ::v-deep {
      .swiper-slide {
        transform: translate3d(calc((100vw - #{$contentContainerMaxWidth}) / 2), 0, 0);
      }
    }
  }
}
/** 手機 */
@media screen and (max-width: $mobileWidth) {
  ::v-deep {
    .swiper-pagination-custom {
      bottom: 8px;
      .swiper-pagination-btn {
        padding: 0;
        width: 8px;
        height: 8px;
        cursor: pointer;
        border-radius: 8px;
        margin-right: 8px;
        transition: all 0.3s;
        &:first-child {
          border-radius: 8px;
        }
        &:last-child {
          border-radius: 8px;
        }
        &__text {
          display: none;
        }
        &.active {
          background: $mainColor;
          width: 16px;
        }
      }
    }
  }
  .page-homepage {
    padding-bottom: 0;
  }
  ::v-deep .swiper-arrow-controls {
    .btn {
      width: 24px;
      height: 24px;
    }
    &.rating, &.recommend-share, &.video-list {
      .prev {
        left: calc(#{$sectionMarginXMobile} - 12px);
      }
      .next {
        right: calc(#{$sectionMarginXMobile} - 12px);
      }
    }
    &.inside {
      .prev {
        left: 20px;
      }
      .next {
        right: 20px;
      }
    }
  }
  .homepage-banner-swiper {
    width: 100vw;
    height: $bannerHeightMobile;
    &__image {
      height: $bannerHeightMobile;
    }
  }
  .card-swiper-container , .customer-rating-section .card-swiper-container {
    min-height: 0;
    ::v-deep {
      .swiper-slide {
        transform: translate3d(calc((100vw - #{$contentContainerMaxWidthMobile}) / 2), 0, 0);
      }
    }
  }
  .smartdaily-intro-section {
    margin-top: 20px;
    .smartdaily-intro {
      width: calc(100% / 2);
    }
  }
  .recommend-article-section {
    margin-top: $sectionMarginYMobile;
    &__article {
      $articleCardMarginRight: 60px;
      width: 100%;
      padding: 16px 0;
    }
    &__see-more {
      margin-top: 12px;
    }
    ::v-deep {
      .article-card {
        &__container {
          &:not(:last-child) {
            border-bottom: 1px solid #eaeaea;
          }
        }
        &__card-container {
          display: flex;
          flex-direction: row;
          padding: 0;
          box-shadow: none;
        }
        &__img-container {
          max-width: 115px;
          width: 100%;
          margin-right: 16px;
        }
        &__title-container {
          &__text {
            font-size: pxTorem(15px);
          }
        }
        &__category-container {
          &__text {
            font-size: pxTorem(13px);
          }
        }
      }
    }
  }
  .recommend-share-section {
    margin-top: 40px;
    &__title {
      margin-bottom: 16px;
    }
    .recommend-share-card-container {
      margin-bottom: 40px;
      ::v-deep {
        .swiper-slide {
          width: 268px;
          margin-top: 0;
          &.fake-slide {
            width: calc(#{$sectionMarginXMobile} + 16px);
          }
        }
      }
    }
  }
  .customer-rating-section {
    margin-top: $sectionMarginYMobile;
    &__title {
      margin-bottom: 0px;
    }
    .customer-rating-card-container {
      min-height: 145px;
      margin-bottom: 0;
      ::v-deep {
        .swiper-slide {
          width: 315px;
          margin-top: 12px;
        }
      }
    }
  }
  .recommend-service-section {
    margin-top: 24px;
    &__title {
      margin-bottom: 12px;
    }
  }
  .video-section {
    margin-top: $sectionMarginYMobile;
  }
  ::v-deep {
    .swiper-pagination-btn {
      padding: 0;
      width: 8px;
      height: 8px;
      cursor: pointer;
      border-radius: 50%;
      margin-right: 8px;
      &:first-child {
        border-radius: 50%;
      }
      &:last-child {
        border-radius: 50%;
      }
      &__text {
        display: none;
      }
    }
    .homepage-loader {
      &__banner {
        height: $bannerHeightMobile;
      }
      &__card-container__card {
        $cardMargin: 16px;
        width: calc((100% - #{$cardMargin}) / 2);
        margin-bottom: $cardMargin;
        &:not(:nth-child(4n)) {
          margin-right: 0;
        }
        &:not(:nth-child(2n)) {
          margin-right: $cardMargin;
        }
      }
    }
  }
}
/** 手機max-width，小於等於時，需切換swiper-slide位置 */
@media screen and (max-width: calc(#{$contentContainerMaxWidthMobile} + #{$sectionMarginXMobile} * 2)) {
  .card-swiper-container, .customer-rating-section .card-swiper-container {
    ::v-deep {
      .swiper-slide {
        transform: translate3d($sectionMarginXMobile, 0, 0);
      }
    }
  }
}
@media screen and (min-width: $mobileWidth) and (max-width: $contentContainerMaxWidthMargin) {
  ::v-deep .swiper-arrow-controls {
    &.rating, &.recommend-share, &.video-list {
      .prev {
        left: calc(#{$sectionMarginX} - 40px);
      }
      .next {
        right: calc(#{$sectionMarginX} - 40px);
      }
    }
  }
  .card-swiper-container, .customer-rating-section .card-swiper-container {
    ::v-deep {
      .swiper-slide {
        transform: translate3d($sectionMarginX, 0, 0);
      }
    }
  }
  .recommend-share-section .recommend-share-card-container {
    ::v-deep {
      .swiper-slide {
        &.fake-slide {
          width: calc(#{$sectionMarginX} * 2 + 40px);
        }
      }
    }
  }
}
/** APP */
@mixin swiperSectionTitleDefinedApp () {
  max-width: none;
  margin: 0 auto calc(#{$sectionTitleMarginBottom} - #{$sliderShadowPadding});
  padding: 0 $sectionMarginXMobile;
}
@mixin sectionLayoutDefinedApp () {
  max-width: none;
  margin-bottom: $sectionTitleMarginBottom;
  padding: 0 $sectionMarginXMobile;
  &__title {
    margin-bottom: $sectionTitleMarginBottom;
  }
}
.is-app {
  padding-bottom: 58px;
  ::v-deep {
    .homepage-loader {
      &__card-container {
        @include sectionLayoutDefinedApp;
      }
    }
  }
  .community-service-section { // 社區專屬服務
    margin-top: $sectionMarginYMobile;
    margin-bottom: -16px;
    overflow: hidden;
    &__title {
      @include swiperSectionTitleDefinedApp;
    }
  }
  .featured-section { // 精選專區
    margin-top: $sectionMarginYMobile;
    overflow: hidden;
    &__title {
      @include swiperSectionTitleDefinedApp;
      height: 30px;
      overflow: hidden;
      justify-content: space-between;
      margin-right: -16px;
    }
    &__see-all__btn {
      &::v-deep {
        .v-btn__content {
          font-size: pxTorem(15px);
          letter-spacing: normal;
          i {
            display: flex;
            width: 16px;
            height: 16px;
          }
        }
      }
    }
  }
  .customer-rating-section { // 用戶好評
    &__title {
      @include swiperSectionTitleDefinedApp;
      margin-bottom: 0;
    }
    .customer-rating-card-container {
      .customer-rating-swiper-slide {
        transform: translate3d(16px, 0, 0);
      }
    }
  }
  .event-section { // 活動專區
    background: #f3f3f3;
    padding-top: $sectionMarginYMobile;
    padding-bottom: $sectionMarginYMobile;
    margin-top: 12px;
  }
  .video-section { // 生活實用小知識
    overflow: hidden;
    &__title {
      @include swiperSectionTitleDefinedApp;
    }
  }
  .recommend-article-section { // 推薦文章
    max-width: none;
    &__title {
      @include swiperSectionTitleDefinedApp;
      padding: 0;
    }
  }
}
</style>
